import md5 from 'md5'
import * as moment from 'moment'
import { enums } from '@/core/enums'

export const preventBodyScroll = (value) => {
  const bodyClasses = document.getElementsByTagName('html')[0].classList

  if (value) {
    bodyClasses.add('prevent-body-scroll')
  } else {
    bodyClasses.remove('prevent-body-scroll')
  }
}

export const getUserAvatarImgUrl = (
  email = '',
  hash = '',
  size = 80,
  defaultImg = 'retro',
  rating = 'g'
) => {
  const img = [
    '//www.gravatar.com/avatar/',
    hash || md5(email.trim().toLowerCase()),
    `?s=${size}`,
    `&d=${defaultImg}`,
    `&r=${rating}`
  ]

  return img.join('')
}

export const validateEmail = (email) => {
  return (!/^\S+@\S+\.\S+$/.test(email)) ? 'E-mail must be valid' : true
}

export const formatNumber = (value, digits = 0) => {
  return new Intl.NumberFormat(undefined, { minimumFractionDigits: digits }).format(value)
}

export const formatDate = (date, format = 'DD/MM/YYYY') => {
  return moment(date).format(format)
}

export const checkOnPlural = (value, singular, plural) => {
  if (typeof value === 'number') {
    return value > 1 ? `${value} ${plural}` : `${value} ${singular}`
  }
  return value
}

export const getAutomationByName = (id) => {
  let value
  Object.keys(enums.AUTOMATION_BY).forEach((key) => {
    if (enums.AUTOMATION_BY[key].id === id) { value = enums.AUTOMATION_BY[key].name }
  })
  return value
}

export const getCampaignBadgeColor = (statusId) => {
  if (statusId === 1) return 'green'
  else if (statusId === 2) return 'orange'
  else if (statusId === 3) return 'grey'
  else if (statusId === 4) return 'blue'
  else if (statusId === 7) return 'red-light'
  else return ''
}

export const getBadgeColor = (statusId) => {
  if (statusId === 1) return 'green'
  else if (statusId === 2) return 'orange'
  else if (statusId === 3) return 'grey'
  else if (statusId === 4) return 'blue'
  else if (statusId === 5) return 'indigo'
  else if (statusId === 6) return 'red'
  else return ''
}

export const getCampaignStatus = (id) => {
  let value
  Object.keys(enums.CAMPAIGN_STATE).forEach((key) => {
    if (enums.CAMPAIGN_STATE[key].id === id) { value = enums.CAMPAIGN_STATE[key].name }
  })
  return value
}

export const getAutomationStatus = (id) => {
  let value
  Object.keys(enums.AUTOMATION_STATUS).forEach((key) => {
    if (enums.AUTOMATION_STATUS[key].id === id) { value = enums.AUTOMATION_STATUS[key].name }
  })
  return value
}

export const getEntitiesStatuses = (entityStatuses) => {
  const statusesArr = []
  Object.keys(entityStatuses).forEach((key) => {
    const campaignStatusCount = entityStatuses[key]

    const badgeColor = getCampaignBadgeColor(parseInt(key))
    const campaignStatus = getCampaignStatus(parseInt(key))

    statusesArr.push({
      badgeColor: badgeColor,
      campaignStatus: `${campaignStatus} (${campaignStatusCount})`
    })
  })

  return statusesArr
}

export const getAutomationTypeName = (id) => {
  let value
  Object.keys(enums.AUTOMATION_TYPE).forEach((key) => {
    if (enums.AUTOMATION_TYPE[key].id === id) {
      value = enums.AUTOMATION_TYPE[key].name
    }
  })
  return value
}

export const debounce = (func, wait = 700) => {
  let timeout
  return function executedFunction (...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export function appendScriptToHead (src) {
  // Check if a script with the same src already exists
  const existingScript = document.querySelector('script[src="' + src + '"]')

  if (existingScript) {
    console.warn('Script already exists:', src)
    return // If script already exists, do not append again
  }

  // Create a new script element
  const scriptElement = document.createElement('script')

  // Set the source attribute to the provided src
  scriptElement.src = src

  // Append the script element to the head of the document
  document.head.appendChild(scriptElement)
}
