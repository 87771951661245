export default [
  {
    path: 'adplatforms',
    name: 'AdPlatforms',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Ad platforms' }]
    },
    component: () => import('@/views/dashboard/AdPlatforms.vue')
  }
]
