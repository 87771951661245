import $axios from '@/core/api.config'
class AdminService {
  getUserTakeOver () {
    return $axios.get('/Admin/UserTakeOver')
  }

  userTakeOverSave (clientId) {
    return $axios.post(`/Admin/UserTakeOver?userClientId=${clientId}`)
  }

  userTakeOverReset () {
    return $axios.post('/Admin/UserTakeOverReset')
  }

  getIsAdmin () {
    return $axios.get('/admin/iuga/')
  }

  getAutomationErrors ({ pageIndex, pageSize }) {
    return $axios.get('/admin/getautomationalerts', {
      params: {
        pageIndex,
        pageSize
      }
    })
  }

  getUsers ({ pageIndex, pageSize, query, sortBy, sortDesc }) {
    return $axios.get('admin/getUsersExtended', {
      params: {
        pageIndex,
        pageSize,
        query,
        sortBy,
        sortDesc
      }
    })
  }

  getAutomatioins ({ pageIndex, pageSize, channel, query, sortBy, sortDesc }) {
    return $axios.get('admin/automation/getall', {
      params: {
        pageIndex,
        pageSize,
        channel,
        query,
        sortBy,
        sortDesc
      }
    })
  }

  getAutomationSummary ({ userClientId, automationId }) {
    return $axios.get('admin/automation/getautomation', {
      params: {
        userClientId,
        automationId
      }
    })
  }

  getAutomationFilter (automationId) {
    return $axios.get('Admin/AutomationFilter/GetByAutomationId', { params: { automationId } })
  }

  getAutomationDefaultState (automationId) {
    return $axios.get('Admin/AutomationDefaultState/GetByAutomationId', { params: { automationId } })
  }

  getAutomationEntitiesById ({ automationId, pageSize, pageIndex }) {
    return $axios.get('Admin/AutomationEntity/GetByAutomationId', {
      params: {
        automationId,
        pageSize,
        pageIndex
      }
    })
  }

  getAutomationAlerts ({ userClientId, automationId, pageIndex, pageSize }) {
    return $axios.get('Admin/Report/AutomationAlerts', {
      params: {
        userClientId,
        automationId,
        pageSize,
        pageIndex
      }
    })
  }

  getSpendByAutomation (automationId, from, to, period) {
    return $axios.get(`/admin/GetSpendsByAutomation/${automationId}/${from}/${to}/${period}`)
  }

  getUserAutomations (params) {
    return $axios.get('admin/automation/get', { params })
  }

  getUserExtended (userId) {
    return $axios.get(`/admin/getUserExtended?userId=${userId}`)
  }

  getUserClient (clientId) {
    return $axios.get(`/admin/UserClient?userClientId=${clientId}`)
  }

  getAdPlatforms (clientId) {
    return $axios.get(`/admin/AdPlatforms?userClientId=${clientId}`)
  }

  getSelectedAccounts (clientId) {
    return $axios.get(`/admin/GetSelectedAccounts?userClientId=${clientId}`)
  }

  getGoogleAccounts (clientId) {
    return $axios.get(`/admin/GetGoogleAccounts?userClientId=${clientId}`)
  }

  getFacebookAccounts (clientId) {
    return $axios.get(`/admin/GetFacebookAccounts?userClientId=${clientId}`)
  }

  getUserSettings (clientId) {
    return $axios.get(`/admin/UserSettings?userClientId=${clientId}`)
  }

  getUserSpends (clientId) {
    return $axios.get(`/Admin/UserSpends?userClientId=${clientId}`)
  }

  getSpendsByParams (channel, from, to, period, userClientId) {
    return $axios.get(`admin/getspendsbyparams/${channel}/${from}/${to}/${period}/${userClientId}`)
  }

  updateUserSettings (clientId, body) {
    return $axios.post(`/admin/UserSettings?userClientId=${clientId}`, body)
  }

  getAdminDashboardKeyMetrics () {
    return $axios.get('/admin/keymetrics')
  }

  getUserSubscription (userClientId) {
    return $axios.get('/admin/GetSubscriptionV2', { params: { userClientId } })
  }

  getClientData (userClientId) {
    return $axios.get('/admin/GetClientData', { params: { userClientId } })
  }

  getAdminUserInvoices (userClientId) {
    return $axios.get('/admin/GetInvoices', { params: { userClientId } })
  }

  getCampaignSummary (id) {
    return $axios.get(`admin/getcampaign/${id}`)
  }

  getAutomation (id) {
    return $axios.get(`admin/getautomation/${id}`)
  }

  getEntityLatestChanges (id) {
    return $axios.get('Admin/EntityLatestChanges', { params: { automationEntityId: id } })
  }

  getEntityLatestChangesChart (automationEntityId, from, to, period) {
    return $axios.get(`Admin/EntityLatestChangesChart/${automationEntityId}/${from}/${to}/${period}`)
  }

  getSpendsByAutomationEntity (automationEntityId, from, to, period) {
    return $axios.get(`admin/GetSpendsByAutomationEntity/${automationEntityId}/${from}/${to}/${period}`)
  }

  getAutomationFilters (userClientId, automationId) {
    return $axios.get('/Admin/automation/getautomation', { params: { userClientId, automationId } })
  }

  getEntityMap (automationId, entityId) {
    return $axios.get(`/admin/getmapdatageojson/${automationId}/${entityId}`)
  }

  getAdwordsAdGroupsByCampaign (userClientId, campaignId, accountId) {
    return $axios.get('/admin/GetAdwordsAdGroupsByCampaign', { params: { userClientId, campaignId, accountId } })
  }

  getFacebookAdsByAdset (userClientId, adsetId) {
    return $axios.get('/admin/getFacebookAdsByAdset', { params: { userClientId, adsetId } })
  }

  validateEmailManually (username) {
    return $axios.post(`/auth/ValidateEmailManually?username=${username}`)
  }
}

export default new AdminService()
