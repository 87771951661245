export default [
  {
    path: '/',
    name: 'AdminUserDetails',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminUsers'
    },
    component: () => import('@/views/admin/AdminUserDetails')
  },
  {
    path: 'automations',
    name: 'AdminUserAutomations',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminUsers'
    },
    component: () => import('@/views/admin/AdminUserAutomations.vue')
  },
  {
    path: 'campaigns',
    name: 'AdminUserCampaigns',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminUsers'
    },
    component: () => import('@/views/admin/AdminUserCampaigns.vue')
  },

  {
    path: 'settings',
    name: 'AdminUserSettings',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminUsers'
    },
    component: () => import('@/views/admin/AdminUserSettings.vue')
  },

  {
    path: 'subscription',
    name: 'AdminUserSubscription',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminUsers'
    },
    component: () => import('@/views/admin/AdminUserSubscription.vue')
  }
]
