import $axios from '@/core/api.config'

class UserService {
  getUserProfile () {
    return $axios.get('/auth/getuserdetails/')
      .then(res => {
        return res
      })
  }

  updateUserProfile (userData) {
    return $axios.post('/auth/updateuserdetails/', userData)
  }

  changePassword (data) {
    return $axios.post('/auth/change-password/', data)
  }

  getClientData () {
    return $axios.get('/auth/getclientdata/')
  }

  setClientData (data) {
    return $axios.post('/auth/setclientdata/', data)
  }

  updateOnboardingQuestions (answers) {
    return $axios.post('/auth/update-onboarding-questions/', answers)
  }

  getConnectors () {
    return $axios.get('/platforms/connectors/')
  }

  getSelectedAccounts () {
    return $axios.get('/platforms/getselectedaccounts')
  }

  deleteSelectedAccounts (data) {
    return $axios.post('/platforms/delselacc', data)
  }

  persistGoogle (authData) {
    return $axios.post('/platforms/persistgoogle', authData)
  }

  getGoogleOAuth2Details () {
    return $axios.get('/platforms/getgoogleoauth2')
  }

  getFacebookAuthDetails () {
    return $axios.get('/platforms/getfacebookdetails')
  }

  revokeGoogle () {
    return $axios.get('/platforms/revokegoogle')
  }

  revokeFacebook () {
    return $axios.get('/platforms/revokefacebook')
  }

  revokeDBM () {
    return $axios.get('/platforms/revokedbm')
  }

  deleteAccountsByChannel (channelId) {
    return $axios(`/platforms/delselaccchannel/${channelId}`)
  }

  validateGoogleCustomerId (customerId) {
    return $axios.get(`/platforms/validatecustomerid/${customerId}`)
  }

  persistGoogleCustomerId (customerId) {
    return $axios.post('/platforms/persistgooglecustomerid', { customerId })
  }

  persistFacebook (authData) {
    return $axios.post('/platforms/persistfacebook', authData)
  }

  persistDBM (code) {
    return $axios.post(`/platforms/persistdbm?code=${code}`)
  }

  getSelectedAccountsByChannel (channelId) {
    return $axios.get(`/platforms/getselectedaccountsbychannel/${channelId}`)
  }

  getDBMOAuth2Details () {
    return $axios.get('/platforms/getdbmoauth2')
  }

  hasValidCustomerId () {
    return $axios.get('/platforms/hasvalidcustomerid/')
  }

  updateSelectedAccounts (selacc, channel) {
    return $axios.post('/platforms/updateselacc', { selacc, channel })
  }

  getGoogleAdManagerOAuth2Details () {
    return $axios.get('/googleadmanager/getoauth2')
  }

  persistGoogleAdManager (authData) {
    return $axios.post('/googleadmanager/persist', authData)
  }

  persistGoogleAds (authData) {
    return $axios.post('/adwords/persist', authData)
  }

  persistDv360 (authData) {
    return $axios.post('/dbm/persist', authData)
  }

  revokeGoogleAdManager () {
    return $axios.get('/googleadmanager/revoke')
  }

  persistTiktokAds (authCode) {
    return $axios.post(`/tiktokads/persist?auth_code=${authCode}`)
  }

  revokeTiktokAds () {
    return $axios.post('/tiktokads/revoke')
  }

  getApiKeysZapier () {
    return $axios.get('/zapier/GetApiKeys')
  }

  CreateApiKeyZapier () {
    return $axios.post('/zapier/CreateApiKey')
  }

  DisableApiKey (key) {
    return $axios.post(`/zapier/DisableApiKey/${key}`)
  }

  getWebhookToken () {
    return $axios.get('/webhook/authToken')
  }

  sendWebhookToken (payload) {
    return $axios.post('/webhook/authToken', payload)
  }
}

export default new UserService()
