import $axios from '@/core/api.config'

class AuthService {
  login (email, password) {
    return $axios.post('/auth/login', { username: email, password })
  }

  logout () {
    return $axios.get('/auth/logout')
  }

  acceptInvitation (fullName, email, password, inviteId) {
    return $axios.post('/auth/acceptinvitation', { fullName, email, password, inviteId })
  }

  recoverPassword (email) {
    return $axios.get(`/auth/recover?email=${email}`)
  }

  resetPassword (email, token, newPassword) {
    return $axios.post('/auth/reset', { email, token: encodeURIComponent(token), newPassword })
  }

  signUp (userData, isLegasyUser = null) {
    return $axios.post('/auth/signup', { ...userData, isLegasyUser })
  }

  confirmEmail (email, token) {
    return $axios.post('/auth/validateemail', { email, token: encodeURIComponent(token) })
  }
}

export default new AuthService()
