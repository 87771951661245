<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { appendScriptToHead } from '@/core/helper-functions'
import { googleApiKey } from '@/core/config'

export default {
  name: 'App',

  created () {
    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&loading=async`

    appendScriptToHead(scriptSrc)
  }
}
</script>
