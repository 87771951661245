import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { Ripple } from 'vuetify/lib/directives'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

export default new Vuetify({
  theme: {
    icons: {
      iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: {
          base: '#0e74f1',
          lighten1: '#4071BF',
          lighten2: '#68a2ff',
          lighten3: '#569ef5',
          lighten4: '#BBDEFB',
          lighten5: '#ecf1f9',
          darken4: '#181c65'
        },
        red: {
          base: '#d90d0d',
          lighten2: '#ff553a',
          lighten23: '#f5e8e7',
          lighten3: '#feede7',
          lighten31: '#FCE9E7',
          lighten4: '#fee7e7',
          lighten5: '#FFEBEE',
          darken2: '#b60000',
          darken3: '#9f0000'
        },
        grey: {
          base: '#9E9E9E',
          lighten1: '#908f8f',
          lighten2: '#c5c5c5',
          lighten3: '#E6E6E6',
          lighten4: '#B7B7B7',
          lighten5: '#FAFAFA',
          lighten6: '#F2F2F2',
          darken2: '#686868',
          darken3: '#555555'
        },
        yellow: {
          base: '#FFEB3B',
          darken2: '#f4c63e',
          darken3: '#f1b80e',
          darken4: '#E58D72'
        },
        amber: {
          base: '#f1b80e',
          lighten1: '#ffea50',
          lighten5: '#f9f5ec',
          darken2: '#bf9335',
          darken3: '#ba8900'
        },
        'light-green': {
          base: '#0aa90a',
          accent3: '#5FBF5F',
          accent4: '#5adc47'
        },
        green: {
          base: '#0aa90a',
          lighten1: '#5adc47',
          lighten4: '#e7fee7',
          lighten5: '#f0faf0',
          darken2: '#5FBF5F',
          darken3: '#007800'
        },
        orange: {
          base: '#FF9800',
          lighten4: '#fef2e7',
          darken4: '#b85200'
        },
        indigo: {
          base: '#3F51B5',
          lighten4: '#ede7fe',
          darken4: '#470ef1'
        },
        blue: {
          lighten4: '#e7f1fe'
        },
        purple: {
          base: '#470ef1',
          lighten6: '#f6f6ff'
        },
        'red-orange': '#f1800e',
        whitesmoke: '#f5f5f5',
        secondary: '#004abd',
        accent: '#304ffe',
        black: '#333333',
        white: '#ffffff',
        success: '#0aa90a',
        warning: '#ff6d00',
        successStandard: '#4caf50',
        error: '#d90d0d',
        darkerGrey: '#908f8f',
        secondaryButton: colors.grey.darken1,
        primaryChart: colors.lightBlue.lighten5,
        secondaryChart: colors.lightBlue.lighten3,
        tertiaryChart: colors.lightBlue.lighten1
      }
    }
  }
})
