import $axios from '@/core/api.config'

class IntercomService {
  APP_ID = process.env.VUE_APP_INTERCOM_APP_ID

  async setIntercomDataAnonymous () {
    try {
      if (!window.Intercom.booted) {
        setTimeout(() => {
          return this.setIntercomDataAnonymous()
        }, 1000)
        return
      }

      await this.disconnectIntercom()

      window.Intercom('boot', {
        app_id: this.APP_ID
      })
    } catch {}
  }

  async setIntercomData () {
    await this.disconnectIntercom()

    return $axios.get('/intercom/getuserdata/')
      .then((res) => {
        window.Intercom('boot', {
          app_id: this.APP_ID,
          email: res.email,
          name: res.user_name,
          user_id: res.user_id,
          created_at: res.created_at,
          user_hash: res.user_hash,
          subscription_status: res.subscriptionStatus,
          trial_expiration_date: res.trialExpirationDate,
          company: res.company,
          user_type: 'v2',
          v2_user_status: res.v2UserStatus
        })

        window.intercomSettings = {
          custom_launcher_selector: '#intercom-link',
          vertical_padding: 70
        }
      })
  }

  async disconnectIntercom () {
    try {
      await window.Intercom('shutdown')
    } catch {}
  }

  async showIntercomMessenger () {
    await window.Intercom('show')
  }
}

export default new IntercomService()
