export default [
  {
    path: 'subscription',
    name: 'Subscription',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Billing and Invoices' }]
    },
    component: () => import('@/views/subscription/Subscription.vue')
  },
  {
    path: 'subscription/checkout',
    name: 'Checkout',
    meta: {
      noOffsets: true,
      requiresAuth: true,
      breadcrumb: [{ name: 'Billing and Invoices', link: 'Subscription' }, { name: 'Subscription Checkout' }]
    },
    component: () => import('@/views/subscription/SubscriptionCheckout.vue')
  },
  {
    path: 'subscription/update',
    name: 'SubscriptionUpdate',
    meta: {
      noOffsets: true,
      requiresAuth: true,
      breadcrumb: [{ name: 'Billing and Invoices', link: 'Subscription' }, { name: 'Subscription Update' }]
    },
    component: () => import('@/views/subscription/SubscriptionUpdate.vue')
  }
]
