// Global vue filters
import Vue from 'vue'
import { formatDate, formatNumber, checkOnPlural } from '@/core/helper-functions'

Vue.filter('formatDate', formatDate)

Vue.filter('formatNumber', (value, digits) => {
  if (!value || value === '-') {
    return value
  }
  if (Number.isNaN(value)) {
    return value
  }
  return formatNumber(value, digits)
})

Vue.filter('checkOnPlural', checkOnPlural)
