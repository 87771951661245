import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPagelines } from '@fortawesome/free-brands-svg-icons'
import {
  faSmog,
  faBacterium,
  faWheatAwn,
  faTree,
  faHandDots,
  faHeadSideVirus,
  faBone,
  faHeadSideMask,
  faHeadSideCough,
  faLungs,
  faFaceGrimace,
  faSkull,
  faHeartCircleCheck,
  faMosquito,
  faBottleWater,
  faSprayCanSparkles,
  faPersonBiking,
  faSignsPost,
  faFishFins,
  faGolfBallTee,
  faPersonHiking,
  faLocationCrosshairs,
  faPersonWalking,
  faPersonRunning,
  faBaseball,
  faSailboat,
  faPersonSnowboarding,
  faPersonSkiing,
  faUmbrellaBeach,
  faDog,
  faGem,
  faFireBurner,
  faMusic,
  faBagShopping,
  faStar,
  faHouseChimney,
  faCampground,
  faSnowman,
  faRecycle,
  faPersonDigging,
  faRoadSpikes,
  faLightbulb,
  faSunPlantWilt,
  faCloudShowersWater,
  faPlaneSlash,
  faPlaneDeparture,
  faCarRear,
  faGasPump,
  faBus,
  faBugs,
  faLocust,
  faBolt,
  faTemperatureArrowUp,
  faFileInvoiceDollar
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Toasted from 'vue-toasted'

Vue.use(Toasted)

library.add(
  faSmog,
  faBacterium,
  faWheatAwn,
  faTree,
  faHandDots,
  faHeadSideVirus,
  faPagelines,
  faBone,
  faHeadSideMask,
  faHeadSideCough,
  faLungs,
  faFaceGrimace,
  faSkull,
  faHeartCircleCheck,
  faMosquito,
  faBottleWater,
  faSprayCanSparkles,
  faPersonBiking,
  faSignsPost,
  faFishFins,
  faGolfBallTee,
  faPersonHiking,
  faLocationCrosshairs,
  faPersonWalking,
  faPersonRunning,
  faBaseball,
  faSailboat,
  faPersonSnowboarding,
  faPersonSkiing,
  faUmbrellaBeach,
  faDog,
  faGem,
  faFireBurner,
  faMusic,
  faBagShopping,
  faStar,
  faHouseChimney,
  faCampground,
  faSnowman,
  faRecycle,
  faPersonDigging,
  faRoadSpikes,
  faLightbulb,
  faSunPlantWilt,
  faCloudShowersWater,
  faPlaneSlash,
  faPlaneDeparture,
  faCarRear,
  faGasPump,
  faBus,
  faBugs,
  faLocust,
  faBolt,
  faTemperatureArrowUp,
  faFileInvoiceDollar
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

// Vue.component(component.name, component)
