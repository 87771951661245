const ENUMS = {
  ROLES: {
    ADMIN: 'admin',
    USER: 'user'
  },

  AUTOMATION_TYPE: {
    notSet: {
      id: 0,
      name: 'Not set'
    },
    facebook: {
      id: 1,
      name: 'Facebook'
    },
    google: {
      id: 2,
      name: 'Google Ads'
    },
    webhook: {
      id: 3,
      name: 'Webhook'
    },
    bing: {
      id: 4,
      name: 'Bing Ads'
    },
    dv360: {
      id: 5,
      name: 'DV360'
    },
    youtube: {
      id: 6,
      name: 'Youtube'
    },
    googleAdManager: {
      id: 7,
      name: 'Google Ad Manager'
    },
    zapier: {
      id: 8,
      name: 'Zapier'
    },
    tiktokAds: {
      id: 9,
      name: 'Tiktok Ads'
    }
  },

  AUTOMATION_STATUS: {
    notSet: {
      id: 0,
      name: 'Not set'
    },
    live: {
      id: 1,
      name: 'Live'
    },
    applying: {
      id: 2,
      name: 'Applying'
    },
    paused: {
      id: 3,
      name: 'Paused'
    },
    scheduled: {
      id: 4,
      name: 'Scheduled'
    },
    draft: {
      id: 5,
      name: 'Draft'
    },
    archived: {
      id: 6,
      name: 'Archived'
    }
  },

  CAMPAIGN_STATE: {
    // notProcessed: {
    //   id: 0,
    //   name: "Not Processed"
    // },
    // started: {
    //   id: 1,
    //   name: "Started"
    // },
    notSet: {
      id: 0,
      name: 'Not Set'
    },
    live: {
      id: 1,
      name: 'Live'
    },
    paused: {
      id: 3,
      name: 'Paused'
    },
    archived: {
      id: 6,
      name: 'Archived'
    },
    error: {
      id: 7,
      name: 'Error'
    }
    // ,
    // pending: {
    //   id: 4,
    //   name: "Pending"
    // }
  },

  AUTOMATION_BY: {
    singleGeo: {
      id: 1,
      name: 'Single geo'
    },
    multiGeo: {
      id: 2,
      name: 'Multi geo'
    }
  },

  SUBSCRIPTION_STATUS: {
    trial: {
      id: 1,
      name: 'Trial'
    },
    active: {
      id: 2,
      name: 'Active'
    },
    cancelled: {
      id: 3,
      name: 'Cancelled'
    },
    expired: {
      id: 4,
      name: 'Expired'
    },
    pastDue: {
      id: 5,
      name: 'PastDue'
    },
    pending: {
      id: 6,
      name: 'Pending'
    }
  },

  SUBSCRIPTION_PAYMENT_PREFERENCE: {
    creditCard: {
      id: 1,
      name: 'CreditCard'
    },
    invoice: {
      id: 2,
      name: 'Invoice'
    },
    paypal: {
      id: 3,
      name: 'Paypal'
    },
    other: {
      id: 4,
      name: 'Other'
    }
  },

  PURCHASE_TYPE: {
    PPC: {
      id: 1,
      name: 'PPC'
    },
    DV360: {
      id: 2,
      name: 'DV360'
    }
  },

  REPORTING_AUTOMATION_ACTION_TYPE: {
    notSet: {
      id: 0,
      name: 'Not set',
      color: '#B2BABB'
    },
    startCampaign: {
      id: 1,
      name: 'Started campaign',
      color: '#2471A3'
    },
    pauseCampaign: {
      id: 2,
      name: 'Paused campaign',
      color: '#E74C3C'
    },
    modifyBid: {
      id: 3,
      name: 'Modified bid',
      color: '#F39C12'
    },
    addLocation: {
      id: 4,
      name: 'Added locations',
      color: '#1E8449'
    },
    removeLocation: {
      id: 5,
      name: 'Removed locations',
      color: '#7D3C98'
    },
    startAdgroup: {
      id: 6,
      name: 'Started adgroup/adset',
      color: '#A3E4D7'
    },
    pauseAdgroup: {
      id: 7,
      name: 'Paused adgroup/adset',
      color: '#FBEEE6'
    },
    startAd: {
      id: 8,
      name: 'Started ad',
      color: '#9A7D0A'
    },
    pauseAd: {
      id: 9,
      name: 'Paused ad',
      color: '#AAB7B8'
    },
    modifyBudget: {
      id: 10,
      name: 'Modified budget',
      color: '#AF7AC5'
    },
    modifyDeliveryType: {
      id: 11,
      name: 'Modified delivery type',
      color: '#AED6F1'
    },
    modifyCampaignBid: {
      id: 12,
      name: 'Modified campaign bid',
      color: '#F0B27A'
    },
    modifyAdgroupBid: {
      id: 13,
      name: 'Modified ad group bid',
      color: '#273746'
    },
    modifyAdgroupKeywordsBid: {
      id: 14,
      name: 'Modified ad group keywords bid',
      color: '#D5D8DC'
    }
  },

  AUTOMATION_UPDATES: {
    NotSet: {
      id: 0,
      name: 'Not set'
    },
    StartCampaign: {
      id: 1,
      name: 'Started Campaign'
    },
    PauseCampaign: {
      id: 2,
      name: 'Paused Campaign'
    },
    ModifyBid: {
      id: 3,
      name: 'Bid modified to'
    },
    AddLocation: {
      id: 4,
      name: 'Added locations'
    },
    RemoveLocation: {
      id: 5,
      name: 'Removed locations'
    },
    StartAdgroup: {
      id: 6,
      name: 'Started adgroup/adset'
    },
    PauseAdgroup: {
      id: 7,
      name: 'Paused adgroup/adset'
    },
    StartAd: {
      id: 8,
      name: 'Started ad'
    },
    PauseAd: {
      id: 9,
      name: 'Paused ad'
    },
    ModifyBudget: {
      id: 10,
      name: 'Budget modified to'
    },
    ModifyDeliveryType: {
      id: 11,
      name: 'Modified delivery type'
    },

    ModifyCampaignBid: {
      id: 12,
      name: 'Modified campaign bid to'
    },
    ModifyAdgroupBid: {
      id: 13,
      name: 'Modified ad group bid to'
    },
    ModifyAdgroupKeywordsBid: {
      id: 14,
      name: 'Modified ad group keywords bid to'
    }
  }
}

Object.freeze(ENUMS)
for (const key in ENUMS) {
  Object.freeze(ENUMS[key])
}

export const enums = ENUMS
