/**
 * @file api-config provides promise-based HTTP abstraction with extended error
 */

import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store'

const $axios = axios.create({
  // setup root url for all http requests
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  // customized XMLHttpRequest wrapper library
  headers: { 'Content-Type': 'application/json' }
})

// global request interceptor
$axios.interceptors.request.use((config) => {
  // intercept url for auth
  // if (store.state.auth.token) {
  //   config.headers.Authorization = `Bearer ${store.state.auth.token}`
  // }

  return config
})

// global response interceptor
$axios.interceptors.response.use(
  response => {
    return response.data
  },
  async (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/auth')) {
      await store.dispatch('auth/logout')
      router.push({ name: 'Login' })
    }

    return Promise.reject(error.response)
  }
)

Vue.prototype.$axios = $axios

export default $axios
