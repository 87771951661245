export default [
  {
    path: 'login',
    name: 'Login',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/Login.vue')
  },

  {
    path: 'recover',
    name: 'RecoverPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/RecoverPassword.vue')
  },

  {
    path: 'reset',
    name: 'ResetPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/ResetPassword.vue')
  },

  {
    path: 'signup',
    name: 'Signup',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/Signup.vue')
  },

  {
    path: 'vip-signup',
    name: 'SignupLegacy',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/SignupLegacy.vue')
  },

  {
    path: 'invite',
    name: 'Invite',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/Invite.vue')
  },

  {
    path: 'success',
    name: 'SignUpSuccess',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/SignUpSuccess.vue')
  },

  {
    path: 'confirmemail',
    name: 'ConfirmEmail',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth/ConfirmEmail.vue')
  }
]
