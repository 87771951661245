import UserRoutes from '../routes/admin.user.routes'

export default [
  {
    path: '/',
    redirect: { name: 'AdminDashboard' }
  },
  {
    path: 'dashboard',
    name: 'AdminDashboard',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminDashboard'
    },
    component: () => import('@/views/admin/AdminDashboard.vue')
  },
  {
    path: 'users',
    name: 'AdminUsers',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminUsers'
    },
    component: () => import('@/views/admin/AdminUsers.vue')
  },
  {
    path: 'errors',
    name: 'AdminAutomationErrors',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('@/views/admin/AdminAutomationErrors.vue')
  },

  {
    path: 'user/:id',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminUsers'
    },
    component: () => import('@/layouts/AdminUserLayout.vue'),
    children: UserRoutes
  },

  {
    path: 'automations',
    name: 'AdminAutomations',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminAutomations'
    },
    component: () => import('@/views/admin/AdminAutomations.vue')
  },

  {
    path: 'automation/summary/:id/:userClientId',
    name: 'AdminAutomationSummary',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminAutomations'
    },
    component: () => import('@/views/admin/AdminAutomationSummary.vue')
  },

  {
    path: 'campaigns/summary/:id/:userClientId',
    name: 'AdminCampaignSummary',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminAutomations'
    },
    component: () => import('@/views/admin/AdminCampaignSummary.vue')
  },

  {
    path: '/automation/filters/:automationId/:userClientId',
    name: 'AdminAutomationFilters',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminAutomations'
    },
    component: () => import('@/views/admin/AdminAutomationFilters.vue')
  },

  {
    path: '/campaigns/adgroups/:campaignId/:userClientId/:accountId',
    name: 'AdminCampaignsAdGroups',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminAutomations'
    },
    component: () => import('@/views/admin/AdminCampaignsAdGroups.vue')
  },

  {
    path: '/campaigns/ads/:adsetId/:userClientId',
    name: 'AdminCampaignsAds',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      activeRoute: 'AdminAutomations'
    },
    component: () => import('@/views/admin/AdminCampaignsAds.vue')
  }
]
