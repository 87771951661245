import store from '@/store'
import IntercomService from '@/services/intercom.service'

export const beforeEach = async (to, from, next) => {
  const isLoggedIn = !!store.state.auth.userToken
  const userProfile = store.state.auth.userProfile

  const isRouteRequiredAuth = to.meta.requiresAuth
  const isRouteRequiredAdmin = to.meta.requiresAdmin

  if (isLoggedIn && !userProfile) {
    await store.dispatch('auth/getUserProfile')
    await store.dispatch('subscription/getTrialData')
    await IntercomService.setIntercomData()
  }

  if (isRouteRequiredAuth && !isLoggedIn) {
    if (to.fullPath.includes('signup')) {
      return next({ name: 'Signup' })
    }
    return next({ name: 'Login' })
  }

  if (to.name === 'Login' && isLoggedIn) {
    next({ name: 'Dashboard' })
  }

  const isAdmin = store.state.auth.isAdmin

  if (isRouteRequiredAdmin && !isAdmin) {
    next({ name: 'Dashboard' })
  }

  next()
}
