import Vue from 'vue'
import App from './App.vue'

import '@/core/filters'
import '@/plugins'

import router from './router'
import store from './store'

import Intercom from './plugins/intercom'

import VueAnalytics from 'vue-analytics'

import vuetify from './plugins/vuetify'
import '@/core/api.config'

import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/styles/main.scss'

import '@/components'

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  router
})

if (process.env.NODE_ENV === 'production') {
  window.onbeforeunload = () => {
    return 'Are you sure you want to leave?'
  }
}

Intercom()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
