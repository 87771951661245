import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth.module'
import { subscription } from './modules/subscription.module'

// Use vuex store
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    subscription
  }
})
