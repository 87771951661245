import $axios from '@/core/api.config'

class SubscriptionService {
  getTermsAndConditions () {
    return $axios.get('/subscription/gettermsandconditions')
  }

  getTrialData () {
    return $axios.get('/subscription/gettrialdata')
  }

  getSubscriptionData () {
    return $axios.get('/subscription/getsubscriptionv2')
  }

  getCurrentContract () {
    return $axios.get('/subscription/getcurrentcontract')
  }

  setSubscriptionActive () {
    return $axios.get('/subscription/setsubscriptionactive')
  }

  getSubscription () {
    return $axios.get('/subscription/get')
  }

  validatePlan (name) {
    return $axios.get('/subscription/validatePlan', { params: { planName: name } })
  }

  ValidateSubscription (filters) {
    return $axios.post('/subscription/ValidateSubscription', filters)
  }

  ValidateSubscriptionV1 () {
    return $axios.get('/subscription/ValidateSubscriptionV1')
  }
}

export default new SubscriptionService()
