export default [
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Dashboard' }]
    },
    component: () => import('@/views/dashboard/Dashboard.vue')
  },

  {
    path: 'profile',
    name: 'Profile',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'My profile' }]
    },
    component: () => import('@/views/dashboard/Profile.vue')
  },

  {
    path: 'connectors',
    name: 'Connectors',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Connectors' }]
    },
    component: () => import('@/views/dashboard/Connectors.vue')
  },
  {
    path: 'connectors/tiktokads',
    name: 'Connectors TiktokAds',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Connectors TiktokAds' }]
    },
    component: () => import('@/views/dashboard/Connectors/TiktokAds.vue')
  },

  {
    path: 'alertcenter',
    name: 'AlertCenter',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Alert Center' }]
    },
    component: () => import('@/views/dashboard/AlertCenter.vue')
  },

  {
    path: 'automations',
    name: 'Automations',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Automations' }],
      noOffsets: true
    },
    component: () => import('@/views/automation/AutomationsList.vue')
  },

  {
    path: 'automation/summary/:id',
    name: 'AutomationSummary',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Automations', link: 'Automations' }, { name: 'Summary' }],
      noOffsets: true,
      activeRoute: 'Automations'
    },
    component: () => import('@/views/automation/AutomationSummary.vue')
  },

  {
    path: 'automation/summary/:id/edit',
    name: 'AutomationSummaryEdit',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Automations', link: 'Automations' }, { name: 'Automation summary' }, { name: 'Automation summary edit' }],
      noOffsets: true,
      activeRoute: 'Automations'
    },
    component: () => import('@/views/automation/AutomationSummaryEdit.vue')
  },

  {
    path: 'automation/default-state/:id',
    name: 'EditAutomationDefaultState',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Automations', link: 'Automations' }, { name: 'Edit automation default state' }],
      noOffsets: true,
      activeRoute: 'Automations'
    },
    component: () => import('@/views/automation/EditAutomationDefaultState.vue')
  },

  {
    path: 'automation/filters/:id',
    name: 'EditAutomationFilters',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Automations', link: 'Automations' }, { name: 'Edit automation filters' }],
      noOffsets: true,
      activeRoute: 'Automations'
    },
    component: () => import('@/views/automation/EditAutomationFilters.vue')
  },

  {
    path: 'automation/new',
    name: 'CreateAutomation',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Create Automation' }],
      noOffsets: true
    },
    component: () => import('@/views/automation/CreateAutomation.vue')
  },

  {
    path: 'campaigns',
    name: 'Campaigns',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Campaigns' }],
      noOffsets: true
    },
    component: () => import('@/views/campaigns/CampaignsList.vue')
  },

  {
    path: 'campaigns/summary/:id',
    name: 'CampaignSummary',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Campaigns', link: 'Campaigns' }, { name: 'Summary' }],
      noOffsets: true,
      activeRoute: 'Campaigns'
    },
    component: () => import('@/views/campaigns/CampaignSummary.vue')
  },

  {
    path: 'reports/dailyspends',
    name: 'Daily Spends',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'Reports' }, { name: 'Daily Spends by Campaigns' }],
      noOffsets: true
    },
    component: () => import('@/views/reports/DailySpends.vue')
  }
]
