import AuthService from '@/services/auth.service'
import UserService from '@/services/user.service.js'
import IntercomService from '@/services/intercom.service'

import CookiesService from '@/core/cookies.service'
import AdminService from '@/services/admin.service'

const state = {
  userToken: CookiesService.getItem('token') || null,
  userId: null,
  userName: null,
  userProfile: null,
  isAdmin: false,
  isUserTakeOver: false,
  error: null
}

const getters = {

}

const mutations = {
  setUserToken (state, token) {
    state.userToken = token
  },

  setUserId (state, id) {
    state.userId = id
  },

  setUserName (state, name) {
    state.userName = name
  },

  setUserProfile (state, userProfile) {
    state.userProfile = userProfile
  },

  setIsAdmin (state, isAdmin) {
    state.isAdmin = isAdmin
  },

  setUserTakeOver (state, isUserTakeOver) {
    state.isUserTakeOver = isUserTakeOver
  },

  setError (state, error = null) {
    state.error = error
  }
}

const actions = {
  login ({ commit, dispatch }, { email, password }) {
    return AuthService.login(email, password)
      .then((response) => {
        if (response) {
          commit('setUserToken', response.token)
          commit('setUserId', response.userId)
          commit('setUserName', response.username)

          CookiesService.setItem('token', response.token, response.tokenExpiry)
        }

        return response
      }).then((res) => {
        dispatch('getUserProfile')
        dispatch('subscription/getTrialData', null, { root: true })
        IntercomService.setIntercomData()

        return res
      })
      .catch((e) => {
        throw new Error(e)
      })
  },

  logout ({ commit }) {
    return AuthService.logout()
      .then((response) => {
        if (response) {
          commit('setUserToken', null)
          commit('setUserId', null)
          commit('setUserName', null)

          CookiesService.remove('token')
        }

        return response
      })
      .catch((e) => {
        commit('setError', 'Logout error')
        throw new Error(e)
      })
  },

  acceptInvitation ({ commit }, { fullName, email, password, inviteId }) {
    return AuthService.acceptInvitation(fullName, email, password, inviteId)
      .then((response) => {
        if (response) {
          commit('setUserToken', response.token)
          commit('setUserId', response.userId)
          commit('setUserName', response.username)

          CookiesService.setItem('token', response.token, response.tokenExpiry)
        }

        return response
      })
  },

  confirmEmail ({ commit }, { email, token }) {
    return AuthService.confirmEmail(email, token)
      .then((response) => {
        if (response) {
          commit('setUserToken', response.token)
          commit('setUserId', response.userId)
          commit('setUserName', response.username)

          CookiesService.setItem('token', response.token, response.tokenExpiry)
        }

        return response
      })
  },

  async getUserProfile ({ commit }) {
    try {
      const { userProfile } = await UserService.getUserProfile()
      const res = await AdminService.getIsAdmin()

      if (userProfile) {
        commit('setUserProfile', userProfile)

        if (res.isUserGlobalAdmin) {
          commit('setIsAdmin', res.isUserGlobalAdmin)
          commit('setUserTakeOver', res.isUserTakeOver)
        }

        return userProfile
      }
    } catch (err) {
      console.log(err)

      return err
    }
  }
}

export const auth = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
