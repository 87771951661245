import SubscriptionService from '@/services/subscription.service.js'

const state = {
  trialData: {
    daysLeft: null,
    isFreeTrialActive: null,
    isLegacyUser: null,
    isTrial: null,
    trialExpirationDate: null,
    trialStartDate: null
  }
}

const getters = {

}

const mutations = {
  setTrialData (state, data) {
    state.trialData.daysLeft = data.daysLeft
    state.trialData.isFreeTrialActive = data.isFreeTrialActive
    state.trialData.isLegacyUser = data.isLegacyUser
    state.trialData.isTrial = data.isTrial
    state.trialData.trialExpirationDate = data.trialExpirationDate
    state.trialData.trialStartDate = data.trialStartDate
  }
}

const actions = {
  async getTrialData ({ commit }) {
    try {
      const response = await SubscriptionService.getTrialData()

      commit('setTrialData', response)

      return response
    } catch (err) {
      return err
    }
  }
}

export const subscription = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
