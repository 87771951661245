import AuthRoutes from './routes/auth.routes'
import DashboardRoutes from './routes/dashboard.routes'
import AdPlatforms from './routes/ad-platforms.routes'
import AdminRoutes from './routes/admin.routes'
import SubscriptionRoutes from './routes/subscription.routes'

export const routes = [
  {
    path: '*',
    redirect: { name: 'Dashboard' }
  },

  {
    path: '/',
    component: () => import('@/layouts/DashboardLayout'),
    children: DashboardRoutes
  },

  {
    path: '/',
    component: () => import('@/layouts/DashboardLayout'),
    children: SubscriptionRoutes
  },

  {
    path: '/',
    component: () => import('@/layouts/DefaultLayout'),
    children: AdPlatforms
  },

  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/layouts/AuthLayout'),
    children: AuthRoutes
  },

  {
    path: '/admin',
    component: () => import('@/layouts/AdminLayout'),
    children: AdminRoutes
  }
]
